html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#__next {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.firebase-emulator-warning {
  display: none;
}

.bmpui-ui-watermark {
  /* background-image: url('../../../assets/RajuLive_Rajucast_logo_vertical_white.svg'); */
  background-image: none;
}

.summary-table {
  width: 100%;
}

/* disable autofill background color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $white;
} */
